// Fonts
.impact {
    font-family: $impact;
}

.muli-light {
    font-family: $muli;
    font-weight: 300;
}

.muli {
    font-family: $muli;
}

.muli-medium {
    font-family: $muli;
    font-weight: 500;
}

.poppins {
    font-family: $poppins;
}

.poppins-light {
    font-family: $poppins;
    font-weight: 300;
}

.poppins-medium {
    font-family: $poppins;
    font-weight: 500;
}

.poppins-bold {
    font-family: $poppins;
    font-weight: 700;
}

.myriad-pro {
    font-family: $myriadPro;
}

.myriad-pro-medium {
    font-family: $myriadPro;
    font-weight: 700;
}

.futura {
    font-family: $openSans;
}

.futura-medium {
    font-family: $openSans;
    font-weight: 600;
}

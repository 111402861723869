.cc-window {
    background-color: rgba($black, 0.85);
    color: $white;

    font-family: $poppins;
    font-weight: 300;

    strong {
        font-weight: bold;
    }

    .cc-message {
        p {
            margin-bottom: 0.5rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .cc-btn {
        background-color: $white;
        border: none;
        color: $black;
        text-transform: uppercase;

        &:hover {
            color: rgba($black, 0.8);
        }
    }
}

// Text Colours
.top-nav-gray {
    color: $topNavBg !important;
}

.top-nav-color {
    color: $topNav-color !important;
}

.heading-blue {
    color: $headingBlue !important;
}

.heading-gray {
    color: $headingGray !important;
}

.text-black {
    color: $textBlack !important;
}

.light-gray {
    color: $lightGray !important;
}

.footer-gray {
    color: $footerGray !important;
}

.login-blue {
    color: $loginBlue !important;
}

.logo-bar-white {
    color: $logoBarBg !important;
}

.warm-gray {
    color: $warmGray !important;
}

// Background Colours
.bg-top-nav-gray {
    background-color: $topNavBg !important;
}

.bg-heading-blue {
    background-color: $headingBlue !important;
}

.bg-heading-gray {
    background-color: $headingGray !important;
}

.bg-text-black {
    background-color: $textBlack !important;
}

.bg-light-gray {
    background-color: $lightGray !important;
}

.bg-footer-gray {
    background-color: $footerGray !important;
}

.bg-login-blue {
    background-color: $loginBlue !important;
}

.bg-logo-bar-white {
    background-color: $logoBarBg !important;
}

.bg-warm-gray {
    background-color: $warmGray !important;
}

// Border Colours
.border-top-nav-gray {
    border-color: $topNavBg !important;
}

.border-heading-blue {
    border-color: $headingBlue !important;
}

.border-heading-gray {
    border-color: $headingGray !important;
}

.border-text-black {
    border-color: $textBlack !important;
}

.border-light-gray {
    border-color: $lightGray !important;
}

.border-footer-gray {
    border-color: $footerGray !important;
}

.border-login-blue {
    border-color: $loginBlue !important;
}

.border-logo-bar-white {
    border-color: $logoBarBg !important;
}

.border-warm-gray {
    border-color: $warmGray !important;
}

.border-very-dark {
    border-color: #1d1d1d !important;
}

// One Health

// Header
// Top navigation
header {
    .top-nav {
        .navbar-nav {
            .nav-link {
                font-size: $topNavSize;
                @include hover-focus() {
                    color: $topNav-hover-color !important;
                }

                &.active,
                &:active {
                    text-decoration: underline;
                }
            }

            .dropdown-item {
                font-size: $topNavSize;
            }

            .dropdown-item.active {
                background-color: rgba($topNavBg, $zeroPt5);
            }

            .dropdown-item:active {
                background-color: rgba($topNavBg, $zeroPt3);
            }
        }
    }

    //market navigation
    .market-nav {
        border: none;
        border-bottom-width: $px3;
        border-bottom-style: solid;

        .navbar-brand {
            img {
                padding-right: $rem0pt5;
                border: none;
                max-width: $px175;
            }
        }

        .navbar-nav {
            .nav-item {
                .bi-search {
                    transform: rotate($deg90);
                }

                .nav-link {
                    font-size: $pt11;
                    border-bottom: solid $px3 $white;
                    max-width: $px152;
                    line-height: $pt13;

                    @include hover-focus() {
                        color: $textBlack !important;
                        border-bottom: solid $px3 $lightGray;
                    }

                    &.active,
                    &:active {
                        border-bottom: solid $px3 $lightGray;
                    }

                    &.dropdown-toggle {
                        &.search {
                            padding-top: $rem0pt9;
                        }

                        .bi-search {
                            width: $pt15;
                            height: $pt15;
                        }

                        &:hover,
                        &.hover,
                        &.active,
                        &:active,
                        &.focus,
                        &:focus {
                            border-bottom: solid x$px3 $white;
                        }

                        &:after,
                        &.dropdown-toggle::after {
                            display: none;
                        }
                    }
                }

                &.dropdown {
                    .dropdown-menu {
                        top: $pcnt138;
                        border-color: $searchBorder;
                        border-radius: $zero;
                        padding: $rem1pt5625;
                        min-width: $rem20;

                        input.search {
                            border-radius: $zero;
                            border-color: $searchBorder;
                            border-right: none;
                        }

                        form.search-form {
                            .input-group-text {
                                background-color: $white;
                                border-radius: $zero;
                                border-left: none;
                            }
                        }
                    }
                }
            }
        }
    }

    // Mobile Navigation
    .mobile-nav {
        border-bottom-style: solid;
        border-bottom-width: $px3;

        .bi-search {
            transform: rotate($deg90);
        }

        hr {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            border: none;
            border-top: 1px solid $lightGray;
            width: 100%;
        }

        .nav-link.active {
            color: rgba($textBlack, 0.7) !important;
        }

        .navbar-brand {
            img {
                height: 40px;
            }
        }

        .navbar-toggler {
            color: $textBlack;
            border-color: $textBlack;
            padding: 0.25rem 0.5rem;
        }

        .dropdown {
            .dropdown-toggle {
                &::after,
                &:after {
                    margin-left: 0.5em;
                }

                &:hover,
                &.hover,
                &.active,
                &:active,
                &.focus,
                &:focus {
                    border-bottom: none;
                    text-decoration: none;
                }
            }

            .dropdown-menu {
                min-width: 4rem;
                border-radius: 0;
                padding: 0;

                .dropdown-item.active {
                    background-color: rgba($topNavBg, 0.5);
                }

                .dropdown-item:active {
                    background-color: rgba($topNavBg, 0.3);
                }
            }
        }

        .search-form {
            margin: 0.75rem 0;

            .input-group-text {
                background-color: $white;
                border-left: none;
            }

            input.search {
                border-right: none;
            }
        }
    }
}

// Footer Sections
    // Address bar
footer {

    a {
        color: $contrastTextForWarmGray;

        &:hover,
        &.hover {
            color: rgba($contrastTextForWarmGray, 0.75);
            text-decoration: none;
        }

        &:active,
        &.active {
            color: $contrastTextForWarmGray
        }
    }

    .address-bar {
        font-size: $pt14;

        .bi {
            margin-top: -3px;
            line-height: 1.1rem;

            &.bi-geo-alt,
            &.bi-telephone-fill {
                font-size: 0.875rem;
                vertical-align: text-top;
                margin-left: 4px;
                margin-right: 4px;
            }

            &.bi-question,
            &.bi-exclamation {
                font-size: 1.4rem;
            }
        }

        .address-part {
            margin-left: 1.6rem;
        }
    }

    // Partner Logos
    .partner-logos {
        .partner-logo {
            img {
                max-height: 75px;
            }
        }
    }

    // Copyright and social icons
    .copyright-social {
        .copyright {
            font-size: $pt9;
        }

        .social {
            font-size: $pt18;

            a {
                color: $white;
            }
        }
    }

    .accessibility-controls {
        .accessibility-toggle {
            color: $textBlack;
            font-size: $pt18;

            .accessibility-toggle-icon {
                font-size: $pt33;
            }

            &:hover,
            &.hover {
                color: inherit;
            }

            &.active,
            &:active {
                color: $headingBlue;
            }
        }
    }
}

main {
    .banner {
        .brand-logo {
            max-width: 200px;
        }

        .featured-certificate-logo {
            max-width: 70px;
            max-height: 70px;
            filter: drop-shadow(0 0 7px rgba(0,0,0,0.5));
        }

        .auth {
            .featured-certificate-logo {
                max-width: 125px;
                max-height: 125px;
                filter: drop-shadow(0 0 7px rgba(0,0,0,0.5));
            }
        }
    }

    // Market Home Page
    .market-banner {
        background-repeat: no-repeat;
        background-position: -80px top;
        background-size: contain;

        border-bottom: 2px solid $textBlack;

        h2 {
            font-size: $pt30;
            margin-bottom: 0;
        }

        h5 {
            margin-bottom: 2rem;
        }
    }

    .market-offerings {
        .offering-title-block {
            padding-left: 3.4rem;

            .go-to {
                font-size: $pt10;
            }

            a {
                color: $white;

                &:hover,
                &.hover,
                &:active,
                &.active, {
                    color: $white;
                }
            }

            .offering-title {
                font-size: $pt24;
                line-height: 35px;
            }
        }

        .offering-thumb {
            display: block;
            width: 100%;
        }

        .title-bar {
            background-repeat: no-repeat;
            background-image: url("/images/one-health-icon.png");
            background-position: 10px 10px;
            background-size: 50px;
        }
    }

    // Market Modules Page
    .market-modules-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-bottom: solid 2px $textBlack;

        &.featured-bg {
            position: relative;
            min-height: 354px;

            &::before {
                position: absolute;
                content: "";
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
            }

            .banner-text.featured-certificate {
                .featured-certificate-logo-container {
                    padding-top: 0;
                    bottom: 15px;
                    right: 15px;
                }
            }
        }

        .banner-text {
            &.auth {
                padding-bottom: 68px;
            }
        };
    }

    .market-modules-title-bar {
        h2 {
            font-size: $pt30;
        }

        div.muli {
            font-size: $mobileFont;
        }
    }

    .certificate-info {
        h3.futura-medium,
        h4.muli-light {
            font-size: $pt20;
        }

        div.muli {
            font-size: $mobileFont;
        }
    }

    .course-block {
        .h5 {
            font-size: $pt17;
        }

        .h6 {
            font-size: $pt10;
        }

        .description {
            font-size: $mobileFont;
        }

        &.media {
            img {
                border: 1px solid $textBlack;
                width: 100%;
            }
        }
    }

    .course-modal,
    .video-modal {
        .modal-body {
            iframe {
                width: 100%;
                border: none;
            }
        }
    }

    // Dashboard
    .account-menu {

        .btn {
            border-radius: 0;
            color: $black;
            border: none;

            &.log-out-link {
                background-color: $ctaSignInBg;
            }
        }

        .nav {
            .nav-item {
                .nav-link {
                    color: inherit;
                    &:active,
                    &.active,
                    &:hover,
                    &.hover {
                        text-decoration: underline;
                        color: inherit;
                    }
                }
            }
        }
    }

    .dashboard-title-bar {
        h2 {
            font-size: $pt30;
        }
    }

    .dashboard-course-block {
        &.media {
            img {
                border: 1px solid $textBlack;
                width: 100%;
            }
        }

        .h5 {
            font-size: $pt17;
        }

        .h6 {
            font-size: $pt10;
        }

        .description {
            font-size: $mobileFont;
        }

        .course-status {
            .btn.btn-status {
                width: $courseStatusButtonWidth;

                &.btn-info.no-hover:hover,
                &.btn-info.no-hover.hover {
                    background-color: $cyan !important;
                    border-color: $cyan !important;
                }
            }

            .course-start {
                color: $white;
                //background-color: $startCourseButtonBg;
            }

            .course-complete {
                color: $black;
                //background-color: $courseCompleteButtonBg;
            }

            .quiz-launcher.quiz-status {
                color: $black;
            }

            .quiz-launcher.quiz-status.quiz-retake {
                color: $white;
            }
        }
    }

    .print-certificate-block {
        .print-certificate-cta {
            max-width: 430px;

            .btn-print {

            }
        }
    }

    // Resources
    .market-resources-banner {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-bottom: solid 2px $textBlack;

        .banner-text {
            &.auth {
                padding-bottom: 68px;
            }

            h2 {
                font-size: $pt30;
            }
        }
    }

    .market-resources-description {
        div.muli {
            font-size: $mobileFont;
        }
    }

    .resources-menu {
        .nav {
            .nav-item {
                .nav-link {
                    color: inherit;
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    text-decoration: none;
                    padding-right: 0.75rem;
                    padding-left: 0.75rem;

                    &:active,
                    &.active,
                    &:hover,
                    &.hover {
                        text-decoration: underline;
                        color: inherit;
                        font-weight: 700;
                    }

                    &::after {
                        content: attr(data-text);
                        content: attr(data-text) / "";
                        height: 0;
                        visibility: hidden;
                        overflow: hidden;
                        user-select: none;
                        pointer-events: none;
                        font-weight: 700;

                        @media speech {
                            display: none;
                        }
                    }
                }
            }
        }

        .dropdown {
            .dropdown-menu {
                .dropdown-item {
                    &.active {
                        background-color: rgba($topNavBg, 0.5);
                    }

                    &:active {
                        background-color: rgba($topNavBg, 0.3);
                    }
                }
            }
        }
    }

    .market-resources-title {
        h2 {
            font-size: $pt30;
        }
    }

    .market-resources-videos {
        p {
            margin-bottom: $rem0pt25;
        }

        img {
            border: 1px solid $textBlack;
            width: 100%;
        }

        .row {
            padding-top: $rem1pt5;
            padding-bottom: $rem1pt5;
        }
    }

    .file-list {
        height: auto;

        .file-list-item {
            display: table-row;

            .file-icon,
            .file-info {
                display: table-cell;
                padding: 0 0.5rem;
            }
        }
    }

    .market-resources-posts {
        .posts-row {
            max-width: $maxPostsRowWidth;
        }

        .card {
            border-radius: 0;
            border: none;

            .card-img-top {
                border-radius: 0;
            }

            .card-body {
                background-color: $warmGray;

                .card-title {
                    a {
                        color: $textBlack;
                    }
                }
            }
        }
    }

    .post-title {
        img {
            width: 100%;
            max-width: 480px;
        }
    }

    .post-content {
        font-size: $mobileFont;
    }

    .account-cta {
        .btn {
            border-radius: 0;
            color: $black;
            border: none;

            &.sign-in {
                background-color: $ctaSignInBg;
            }

            &.register {
                background-color: $ctaRegisterBg;
            }
        }
    }

    #loginForm {
        .btn.btn-link {
            color: $black;
        }
    }

    .bg-light-gray {
        .form-text {
            a {
                color: $linkOnLightGray;
                text-decoration: underline;
            }
        }
    }

    .bg-warm-gray {
        a:not(.btn):not([data-toggle="modal"]) {
            color: $linkOnWarmGray;
            text-decoration: underline;
        }

        .form-check .form-check-label a {
            color: $linkOnWarmGray;
            text-decoration: underline;
        }

        .form-check-input.is-invalid ~ .form-check-label a {
            color: #dc3545;
        }
    }
}

.icon {
    width: 100%;
    height: 100%;
    position: relative;

    img {
        border: none !important;
        opacity: 1;
        transition: $hoverTransition;
    }
}

.icon-hover {
    width: 100%;
    height: 100%;
    float: left;
    overflow: hidden;
    position: relative;
    text-align: center;
    cursor: pointer;

    .icon-hover-overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        overflow: hidden;
        top: 0;
        left: 0;
        padding: 19.5% 38.1%;
        opacity: 1;
    }

    img {
        display: block;
        position: relative;
        width: 100%;
    }

    &:hover {
        .icon-hover-overlay {
            img {
                opacity: 0.75;
            }
        }
    }
}

::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
    color: $placeholderText;
    opacity: 1;
}

select {
    color: $placeholderText !important;
}

.photo-field-row {
    margin-left: -21px;
    margin-right: -21px;

    .col-md-10 {
        padding-left: 21px;
        padding-right: 21px
    }
}

.grecaptcha-badge {
    bottom: 75px !important;
}

span.btn {
    &:not(:disabled):not(.disabled) {
        cursor: default;
    }
}

.market-btn-primary:disabled,
.market-btn-primary.disabled {
    background-color: $primaryDisabledBackground;
    border-color: $primaryDisabledBorder;
    color: $textBlack;
}

.tooltip-inner {
    background-color: $offWhite;
    color: $black;
    border: 1px solid $midGray;
}

.my-profile {
    form {
        label {
            font-size: 0.97rem;
        }
    }
}

label[for="profilePhoto"] ~ img {
    display: block;
    max-height: 120px;
    width: auto;
}

.hs-form .hs-form-required {
    color: $contactFormRequiredRed !important;
}

.modal .modal-body a {
    text-decoration: underline;
}

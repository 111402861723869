.hbspt-form {
    .hs-form {
        .hs-form-field {
            label,
            .hs-input,
            .hs-error-msg {
                font-family: $poppins;
            }
        }

        .hs-submit {
            .hs-button {
                font-weight: normal;
                font-family: $poppins;
                font-size: $pt10;

                &:hover,
                &.hover,
                &:hover:not(.inactive),
                &:active,
                &.active,
                &:active:not(.inactive) {
                    font-family: $poppins;
                }
            }
        }
    }
}

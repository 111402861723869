// Typography
$impact: 'Anton', sans-serif;
$muli: 'Mulish', sans-serif;
$poppins: 'Poppins', sans-serif;
$myriadPro: 'PT Sans', sans-serif;
$openSans: 'Open Sans', sans-serif;

// Colours
$topNavBg: #565856; //#7b7d7a;
$headingBlue: #1a69a2;
$headingGray: #3d3a3a;
$textBlack: #1d1d1d;
$lightGray: #b0b2ae; /*address bar and market home*/
$footerGray: #3a3b39;
$loginBlue: #3a74ad;
$logoBarBg: #f2f1f0;
$warmGray: #f1f2ee;
$searchBorder: #e1e1e1;
$contrastTextForWarmGray: #262725;
$ctaSignInBg: #77c6ec;
$ctaRegisterBg: #e3723b;
$placeholderText: #6c757d;
$backgroundGreen: #28a745;
$primaryDisabledBackground: #c0c0bb;
$primaryDisabledBorder: #94948f;
$offWhite: #eee;
$midGray: #888;
$startCourseButtonBg: #0056B3;
$courseCompleteButtonBg: #2AAC48;
$linkOnLightGray: #00264D;
$linkOnWarmGray: #004FA3;
$contactFormRequiredRed: #A80000;
$success: $courseCompleteButtonBg;

// Bootstrap Overrides
$topNav-color:                 rgba($white, 1) !default;
$topNav-hover-color:           rgba($white, .75) !default;

// Font Sizes
$topNavSize: 0.7rem;
$pt9: 0.75rem;
$pt10: 0.8333rem;
$pt11: 0.9167rem;
$pt12: 1rem;
$pt13: 1.0833rem;
$pt14: 1.1667rem;
$pt15: 1.25rem;
$pt16: 1.3333rem;
$pt17: 1.4167rem;
$pt18: 1.5rem;
$pt19: 1.5833rem;
$pt20: 1.6667rem;
$pt21: 1.75rem;
$pt22: 1.8333rem;
$pt22dot5: 1.875rem;
$pt24: 2rem;
$pt26: 2.1667rem;
$pt28: 2.3333rem;
$pt30: 2.5rem;
$pt32: 2.6667rem;
$pt33: 2.75rem;
$pt36: 3rem;
$pt42: 3.5rem;
$pt48: 4rem;
$pt54: 4.5rem;
$pt60: 5rem;

$mobileFont: $pt14;
$mediumUpFont: $pt16;

// courses
$courseThumbMaxWidth: 312px;
$courseBaseHeight: 1200px;
$courseBaseWidth: 675px;
$courseHeight: 100% / ($courseBaseHeight / $courseBaseWidth);
$courseStatusButtonWidth: 176px;

$hoverTransition: 0.3s;
$maxPostsRowWidth: 1536px;

// Pixel sizes
$px-45: -45px;
$px-30: -30px;
$px2: 2px;
$px3: 3px;
$px152: 152px;
$px175: 175px;

// Rem sizes
$rem0pt25: 0.25rem;
$rem0pt5: 0.5rem;
$rem0pt9: 0.9rem;
$rem1pt5: 1.5rem;
$rem1pt5625: 1.5625rem;
$rem2: 2rem;
$rem20: 20rem;

// Unitless sizes
$zero: 0;
$zeroPt3: 0.3;
$zeroPt5: 0.5;
$zeroPt9: 0.9;

// Degrees
$deg90: 90deg;

// Percent sizes
$pcnt138: 138%;

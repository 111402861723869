@media (min-width: 375px) {
    main {
        .banner {
            .featured-certificate-logo {
                max-width: 100px;
                max-height: 100px;
            }

            .auth {
                .featured-certificate-logo {
                    max-width: 125px;
                    max-height: 125px;
                }
            }
        }
    }
}

@media (min-width: 425px) {
    main {
        .banner {
            .featured-certificate-logo {
                max-width: 125px;
                max-height: 125px;
            }
        }
    }
}

@media (min-width: map-get($grid-breakpoints, 'sm')) {
    main {
        .market-banner {
            .market-headings {
                width: 50%;
                margin-left: 50%;
            }

            h2 {
                font-size: $pt36;
            }

            h5 {
                margin-bottom: 2.25rem;
            }
        }

        .market-offerings {
            .offering-heading {
                font-size: $pt16;
            }

            .title-bar {
                background-position: 10px 12px;
                background-size: 60px;
            }

            .offering-title-block {
                padding-left: 4rem;

                .offering-title {
                    font-size: $pt36;
                    line-height: 45px;
                }
            }
        }

        .market-modules-banner {
            .banner-text {
                .line-one {
                    font-size: $pt14;
                    line-height: 1.2em;
                }

                .line-two {
                    font-size: $pt30;
                    line-height: 1.1em;
                }
            }
        }

        .market-modules-title-bar {
            h2 {
                font-size: $pt36;
            }
        }

        .course-block,
        .dashboard-course-block {
            &.media {
                img {
                    max-width: $courseThumbMaxWidth;
                }
            }
        }

        .dashboard-title-bar,
        .market-resources-title {
            h2 {
                font-size: $pt36;
            }
        }

        .market-resources-banner {
            .banner-text {
                h2 {
                    font-size: $pt36;
                }
            }
        }

        .market-resources-videos {
            img {
                max-width: $courseThumbMaxWidth;
            }

            .row {
                padding-top: $rem2;
                padding-bottom: $rem2;
                margin-left: $px-30;
                margin-right: $px-30;
            }
        }

        .post-title {
            h3 {
                font-size: $pt24;
            }

            p {
                font-size: $pt14;
            }
        }

        .banner {
            .featured-certificate-logo {
                max-width: 150px;
                max-height: 150px;
                filter: drop-shadow(0 0 10px rgba(0,0,0,0.5));
            }
        }
    }

    .section-padding {
        padding: 2rem;
    }

    .icon-hover {
        max-width: $courseThumbMaxWidth;
    }

    footer {
        .address-bar {
            .address-part {
                margin-left: 0;
            }
        }
    }
}

@media (min-width: map-get($grid-breakpoints, 'md')) {
    main {
        .market-banner {
            background-position: -40px top;

            .market-headings {
                width: 44%;
                margin-left: 56%;
            }

            h2 {
                font-size: $pt42;
            }

            h5 {
                font-size: $pt16;
                margin-bottom: 2.5rem;
            }
        }

        .market-offerings {
            .offering-heading {
                font-size: $pt14;
            }

            .offering-title-block {
                .offering-title {
                    font-size: $pt30;
                    line-height: 40px;
                }
            }
        }

        .account-cta {
            .cta-copy {
                width: 50vh;
                margin-left: auto;
            }
        }

        .market-modules-title-bar {
            h2 {
                font-size: $pt42;
            }

            div.muli {
                font-size: $pt16;
            }
        }

        .certificate-info {
            h3.futura-medium,
            h4.muli-light {
                font-size: $pt22;
            }

            div.muli {
                font-size: $mobileFont;
            }
        }

        .course-block,
        .dashboard-course-block {
            .h5 {
                font-size: $pt21;
            }

            .h6 {
                font-size: $pt14;
            }

            .description {
                font-size: $mediumUpFont;
            }
        }

        .dashboard-title-bar,
        .market-resources-title  {
            h2 {
                font-size: $pt42;
            }
        }

        .market-resources-banner {
            .banner-text {
                &.auth {
                    padding-bottom: 52px;
                }
                h2 {
                    font-size: $pt42;
                }
            }
        }

        .market-modules-banner {
            .banner-text {
                &.auth {
                    padding-bottom: 52px;
                }
            }
        }

        .market-resources-description {
            div.muli {
                font-size: $mediumUpFont;
            }
        }

        .market-resources-videos {
            .row {
                margin-left: $px-45;
                margin-right: $px-45;
            }
        }

        .post-title {
            h3 {
                font-size: $pt28;
            }

            p {
                font-size: $pt15;
            }
        }

        .post-content {
            font-size: $mediumUpFont;
        }
    }

    .section-padding {
        padding: 2rem 3rem;
    }
}

@media (min-width: map-get($grid-breakpoints, 'lg')) {
    main {
        .market-banner {
            background-position: left top;

            .market-headings {
                width: auto;
                margin-left: 0;
            }

            h5 {
                font-size: $pt20;
            }
        }

        .market-offerings {
            .offering-heading {
                font-size: $pt16;
            }

            .offering-title-block {
                .offering-title {
                    font-size: $pt36;
                    line-height: 45px;
                }
            }
        }

        .market-modules-title-bar {
            h2 {
                font-size: $pt54;
            }
        }

        .dashboard-title-bar,
        .market-resources-title  {
            h2 {
                font-size: $pt54;
            }
        }

        .media.dashboard-course-block {
            .media-body {
                flex: 4;
            }

            .course-status {
                flex: 1;
            }
        }

        .market-resources-banner {
            .banner-text {
                h2 {
                    font-size: $pt54;
                }
            }
        }

        .post-title {
            h3 {
                font-size: $pt30;
            }

            p {
                font-size: $pt16;
            }
        }
    }
}

@media (min-width: 1056px) {
    main {
        .market-modules-banner.featured-bg::before {
            background-position: right bottom;
            background-size: contain;
        }
    }
}

@media (min-width: map-get($grid-breakpoints, 'xl')) {
    main {
        .market-banner {
            h2 {
                font-size: $pt48;
            }

            h5 {
                font-size: $pt22dot5;
                margin-bottom: 3rem;
            }
        }

        .market-offerings {
            .title-bar {
                background-position: 7px 14px;
                background-size: 65px;
            }
            .offering-title-block {
                .offering-title {
                    font-size: $pt42;
                    line-height: 55px;
                }
            }
        }

        .post-title {
            h3 {
                font-size: $pt30;
            }
        }
    }
}
